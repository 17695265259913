import {
    GET_PARTNER_APPLICATIONS_WORKFLOW,
    CLEAR_PARTNER_APPLICATIONS_WORKFLOW,
    SET_LOADING_PARTNER_APPLICATIONS,
    SET_ACTIVE_STEP_PARTNER_APPLICATIONS,
    GET_PARTNER_APPLICATIONS_WORKFLOW_NEXT,
    CLEAR_PARTNER_APPLICATIONS_WORKFLOW_SUCCESS,
    SET_APPLICATION_DECLARATION
} from '../_actions/types';


const initialState = {
    data: {},
    applicationDeclaration:{
        hasRelation: false,
        relationInfo: '',
        hasPartTimeJob: false,
        partTimeJobDetails: '',
        hasVendorRelation: false,
        vendorRelationInfo: '',
    },
    loading: false,
    activeStep: -1,
    success: false
}

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_PARTNER_APPLICATIONS_WORKFLOW:
            return {
                ...state,
                data: action.payload,
                applicationDeclaration:action.payload.applicationDeclaration?action.payload.applicationDeclaration:{...state.applicationDeclaration},
                loading: false
            }
        case SET_APPLICATION_DECLARATION:
            return {
                    ...state,
                    applicationDeclaration: action.payload,
                    loading: false
            }
        case GET_PARTNER_APPLICATIONS_WORKFLOW_NEXT:
            return {
                ...state,
                data: action.payload,
                loading: false,
                activeStep: state.activeStep + 1,
                success: true
            }
        case CLEAR_PARTNER_APPLICATIONS_WORKFLOW:
            return {
                ...state,
                data: {},
                loading: false,
                activeStep: -1
            }

        case SET_LOADING_PARTNER_APPLICATIONS:
            return {
                ...state,
                loading: action.payload
            }
        case SET_ACTIVE_STEP_PARTNER_APPLICATIONS:
            return {
                ...state,
                activeStep: action.payload
            }
        case CLEAR_PARTNER_APPLICATIONS_WORKFLOW_SUCCESS:
            return {
                ...state,
                success: false
            }
        default:
            return state;
    }
}